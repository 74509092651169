import React from 'react';
import './App.css';
import logo from './logo.svg'; // Import the logo image

function App() {
  return (
    <div className="App" style={{ backgroundColor: 'white', color: 'black' }}>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="AgriWeiss Logo" /> {/* Use the logo */}
        <p>Wir bieten IoT-Geräte mit geringem Stromverbrauch an, mit denen Landwirte die Bodenfeuchtigkeit und -temperatur überwachen können.</p>
<br></br>
<p>Funktionen:</p>
<ul>
  <li>Echtzeit-Datenüberwachung</li>
  <li>Niedriger Energieverbrauch</li>
  <li>Einfache Installation</li>
</ul>
<br></br>
<button>Kontaktiere uns</button>
      </header>
    </div>
  );
}

export default App;
